import * as React from 'react';
import _ from 'lodash';
import {
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  MenuItem,
  Paper,
  Slider,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import ModalDialogTopicsEdit from '../modal/ModalDialogTopicsEdit';

import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import {useTranslation} from '../../../../../providers/TranslationProvider';
import CallSplit from "@mui/icons-material/CallSplit";
import Tooltip from '@mui/material/Tooltip';
import {useSnackbar} from 'notistack';
import axios from '../../../../../../api/axios/axiosInstance';
import apiPaths from '../../../../../../api/apiPaths'
import {status200} from '../../../../../../api/status.utils';
import {generateID} from "../../../../../../utils/utils";
import {MuiItem as Item} from './styles/policy';
import CustomSegmentation from "./CustomSementation";
import {useLoading} from "../../../../../providers/LoadingProvider";
import TopicImageManager from "./ImageGeneration";


export default function Policy({
                                 policy,
                                 dataRegion,
                                 parentCandidates,
                                 variables,
                                 handlePolicyChange,
                                 handleSaveScheme,
                                 collection,
                                 weight
                               }) {
  // Stepper Form
  const {enqueueSnackbar} = useSnackbar();
  const {t, lng} = useTranslation();
  const [activeStep, setActiveStep] = React.useState(0);
  const [answerVar, setAnswerVar] = React.useState('');
  const [audioVar, setAudioVar] = React.useState('')
  const [answerVars, setAnswerVars] = React.useState([]);
  const [audioVars, setAudioVars] = React.useState([]);
  const [topics, setTopics] = React.useState([]);
  const [maxTopics, setMaxTopics] = React.useState(5);
  const [maxClassificationTopics, setMaxClassificationTopics] = React.useState(1);
  const [dataset, setDataset] = React.useState('');
  const [inferenceMode, setInferenceMode] = React.useState("llm");
  const [open, setOpen] = React.useState(false);
  const [amountSamples, setAmountSamples] = React.useState(5);
  const [customSegmentDefinitions, setCustomSegmentDefinitions] = React.useState([]);
  const [enableSegmentClassification, setEnableSegmentClassification] = React.useState(false);
  const [segmentationCandidates, setSegmentationCandidates] = React.useState([]);
  const [selectedClassificationSegment, setSelectedClassificationSegment] = React.useState(null);
  const [enableCustomSegmentations, setEnableCustomSegmentations] = React.useState(false);
  const [parentPolicy, setParentPolicy] = React.useState(null);
  const {
    setIsLoading,
    setIsTopicInferenceLoading,
    setIsClassificationLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();

  const fillLocalData = () => {
    setAnswerVars(variables.filter(d => d.category === 'answer'))
    if (policy.answerVar) {
      setAnswerVar(policy.answerVar);
    }
    setAudioVars(variables.filter(d => d.category === 'audio'));
    if (policy.audioVar) {
      setAudioVar(policy.audioVar);
    }
    setSegmentationCandidates(variables.filter(d => d.category === 'categorical'));

    if (policy.topics.length === 0) {
      setMaxTopics(5)
      policy.maxTopics = 5
    } else {
      setMaxTopics(policy.topics.length)
      setTopics(policy.topics.map(topic => {
        let localTopic = {...topic};
        if (!localTopic.hasOwnProperty("representativity")) {
          localTopic.representativity = 0
        }
        return localTopic;
      }).sort((a, b) => (a.representativity && b.representativity) ? b.representativity - a.representativity : a.topic.localeCompare(b.topic)))
    }
    setMaxClassificationTopics((policy.maxClassificationTopics || 1));
    if (policy.amountSamples) {
      setAmountSamples(policy.amountSamples);
    } else {
      setAmountSamples(5);
      policy.amountSamples = 5
    }
    if (policy.customSegmentations && policy.customSegmentations?.enabled) {
      setEnableCustomSegmentations(true);
      setCustomSegmentDefinitions(policy.customSegmentations.segmentations);
    } else {
      policy.customSegmentations = {
        enabled: false,
        segmentations: []
      };
    }
    setSelectedClassificationSegment(policy.selectedClassificationSegment);
    setEnableSegmentClassification(policy.enabledSegmentClassification);
    setDataset(collection)
    setParentPolicy(policy.parentPolicy || null);
  }

  async function updateTopicIcons(topics) {
    let localTopics = [...topics];
    let iconRepresentationResp = await axios.post(apiPaths.topic_icons, {topics: localTopics}, status200);
    if (iconRepresentationResp && iconRepresentationResp.status && iconRepresentationResp.data && iconRepresentationResp.data.topics) {
      let topicsIcons = iconRepresentationResp.data.topics.sort((a, b) => (a.representativity && b.representativity) ? b.representativity - a.representativity : a.topic.localeCompare(b.topic));
      setTopics(topicsIcons);
      return topicsIcons;
    }
    return topics;
  }

  const computeTopicCoverages = async (topics) => {
    setIsLoading(true);
    setTitle("Matching the topics with your data")
    setShowProgressBar(true);
    setProgress(0);
    setProgressMessage("This will only take a few seconds...")
    let localTopics = [...topics];
    let inferenceMode = "vector";
    let sourceClassificationVar = !!policy.parentPolicy ? (policy.parentPolicy.answerVar + "_label") : null;
    if (enableSegmentClassification) {
      sourceClassificationVar = policy.selectedClassificationSegment.propName;
    }
    const payload = {
      collection_name: collection,
      answers_column: policy.answerVar,
      sourceClassificationVariable: sourceClassificationVar,
      parentTopics: !!policy.parentPolicy ? policy.parentPolicy.topics : null,
      topics: localTopics,
      model: inferenceMode,
      lang: lng,
      saveClassification: true,
      max_topics: maxClassificationTopics,
      region: dataRegion||"US",
    };

    try {
      let resp = await axios.post(!!sourceClassificationVar ? apiPaths.compute_partial_classification : apiPaths.compute_classification, payload, status200);
      if (resp && resp.data) {
        policy.inferenceMode = "llm";
        setInferenceMode("llm");
        localTopics = resp.data.updatedTopics;
        localTopics = localTopics.sort((a, b) => (a.representativity && b.representativity) ? b.representativity - a.representativity : a.topic.localeCompare(b.topic));
        setTopics(localTopics);
        policy.topics = localTopics
        policy.classified = true;
      }
      policy.topics = await updateTopicIcons(localTopics)
      handlePolicyChange(policy);
      // await handleSaveScheme();
    } catch (error) {
      console.log(error);
      errorToast(`Error computing topic representativity`);
    }
    setIsLoading(false);
    setTitle('');
    setProgressMessage("")
    setProgress(0)
  }


  const getPredefinedCustomCategories = () => {
    const intentCategories = {
      "english": [
        {
          "topic": "Feedback",
          "description": "This category encompasses all responses aimed at providing opinions or evaluations about a product, service, or experience. It includes both positive and negative feedback, offering insights into what respondents like or dislike."
        },
        {
          "topic": "Suggestions",
          "description": "Responses that propose improvements, new ideas, or constructive criticism fall under this category. These insights are valuable for innovation and addressing areas that need enhancement."
        },
        {
          "topic": "Inquiries",
          "description": "This includes questions or requests for more information about a product, service, policy, or procedure. Responses in this category often indicate areas where additional clarity or resources are needed."
        },
        {
          "topic": "Experiences",
          "description": "Captures personal stories or accounts related to the use of a product or service, or participation in an event. This category is essential for understanding the respondent's journey and emotional connection."
        },
        {
          "topic": "Problems or Issues",
          "description": "Identifies responses highlighting specific problems, issues, or challenges encountered with a product, service, or process. This category is crucial for immediate attention and rectification."
        },
        {
          "topic": "Praise",
          "description": "Consists of responses that specifically express satisfaction, commendation, or positive reactions to an aspect of a product or service. It’s important for recognizing strengths and areas of success."
        },
        {
          "topic": "Intent to Use",
          "description": "This category captures the respondent's intentions regarding the future use of a product or service, including potential purchase, continued use, or discontinuation. It provides insights into customer loyalty and potential sales."
        },
        {
          "topic": "Comparisons",
          "description": "Includes responses that compare a product, service, or experience with another, offering insights into competitive positioning and respondent preferences."
        },
        {
          "topic": "Requirements or Needs",
          "description": "Responses that articulate specific needs, desires, or expectations from a product or service. This category helps in understanding the gap between what is offered and what is expected."
        },
        {
          "topic": "Unsolicited Ideas",
          "description": "Captures innovative or creative ideas that do not necessarily fit into a direct suggestion for improvement but offer potential new directions or opportunities for exploration."
        },
        {
          "topic": "Other",
          "description": "This category captures responses that do not fall under any of the other categories."
        }
      ],
      "spanish": [
        {
          "topic": "Retroalimentación",
          "description": "Esta categoría abarca todas las respuestas destinadas a proporcionar opiniones o evaluaciones sobre un producto, servicio o experiencia. Incluye tanto retroalimentación positiva como negativa, ofreciendo perspectivas sobre lo que a los encuestados les gusta o disgusta."
        },
        {
          "topic": "Sugerencias",
          "description": "Las respuestas que proponen mejoras, nuevas ideas o críticas constructivas entran en esta categoría. Estos conocimientos son valiosos para la innovación y para abordar áreas que necesitan mejora."
        },
        {
          "topic": "Consultas",
          "description": "Incluye preguntas o solicitudes de más información sobre un producto, servicio, política o procedimiento. Las respuestas en esta categoría a menudo indican áreas donde se necesita más claridad o recursos."
        },
        {
          "topic": "Experiencias",
          "description": "Captura historias personales o relatos relacionados con el uso de un producto o servicio, o la participación en un evento. Esta categoría es esencial para comprender el viaje y la conexión emocional del encuestado."
        },
        {
          "topic": "Problemas o Incidencias",
          "description": "Identifica respuestas que resaltan problemas, incidencias o desafíos específicos encontrados con un producto, servicio o proceso. Esta categoría es crucial para la atención inmediata y la corrección."
        },
        {
          "topic": "Elogios",
          "description": "Consiste en respuestas que expresan específicamente satisfacción, reconocimiento o reacciones positivas hacia un aspecto de un producto o servicio. Es importante para reconocer fortalezas y áreas de éxito."
        },
        {
          "topic": "Intención de Uso",
          "description": "Esta categoría captura las intenciones del encuestado respecto al uso futuro de un producto o servicio, incluyendo la posible compra, uso continuado o discontinuación. Proporciona perspectivas sobre la lealtad del cliente y ventas potenciales."
        },
        {
          "topic": "Comparaciones",
          "description": "Incluye respuestas que comparan un producto, servicio o experiencia con otro, ofreciendo perspectivas sobre la posición competitiva y las preferencias del encuestado."
        },
        {
          "topic": "Requerimientos o Necesidades",
          "description": "Respuestas que articulan necesidades, deseos o expectativas específicas de un producto o servicio. Esta categoría ayuda a entender la brecha entre lo que se ofrece y lo que se espera."
        },
        {
          "topic": "Ideas No Solicitadas",
          "description": "Captura ideas innovadoras o creativas que no necesariamente encajan en una sugerencia directa para mejora pero ofrecen potenciales nuevas direcciones u oportunidades para exploración."
        },
        {
          "topic": "Otro",
          "description": "Esta categoría captura respuestas que no se encuentran en ninguna de las categorías anteriores."
        }
      ],
      "arabic": [
        {
          "topic": "التغذية الراجعة",
          "description": "تشمل هذه الفئة كل الردود الهادفة إلى تقديم آراء أو تقييمات حول منتج، خدمة، أو تجربة. تتضمن كلًا من التغذية الراجعة الإيجابية والسلبية، مقدمةً رؤى حول ما يعجب أو لا يعجب المستجيبين."
        },
        {
          "topic": "الاقتراحات",
          "description": "الردود التي تقترح تحسينات، أفكار جديدة، أو نقد بناء تقع ضمن هذه الفئة. هذه الرؤى ثمينة للابتكار ومعالجة المجالات التي تحتاج إلى تحسين."
        },
        {
          "topic": "الاستفسارات",
          "description": "يشمل ذلك الأسئلة أو طلبات للحصول على مزيد من المعلومات حول منتج، خدمة، سياسة، أو إجراء. الردود في هذه الفئة غالبًا ما تشير إلى المجالات التي تحتاج إلى مزيد من الوضوح أو الموارد."
        },
        {
          "topic": "التجارب",
          "description": "تلتقط القصص الشخصية أو الحسابات المتعلقة باستخدام منتج أو خدمة، أو المشاركة في حدث. هذه الفئة ضرورية لفهم رحلة المستجيب والتواصل العاطفي."
        },
        {
          "topic": "المشاكل أو القضايا",
          "description": "تحدد الردود التي تسلط الضوء على مشاكل محددة، قضايا، أو تحديات تواجه مع منتج، خدمة، أو عملية. هذه الفئة حاسمة للانتباه الفوري والتصحيح."
        },
        {
          "topic": "الثناء",
          "description": "تتألف من الردود التي تعبر عن الرضا، الإشادة، أو ردود الفعل الإيجابية تجاه جانب معين من منتج أو خدمة. هذا مهم للاعتراف بالنقاط القوية ومجالات النجاح."
        },
        {
          "topic": "نية الاستخدام",
          "description": "تلتقط هذه الفئة نوايا المستجيب بخصوص استخدام منتج أو خدمة في المستقبل، بما في ذلك الشراء المحتمل، الاستخدام المستمر، أو التوقف عن الاستخدام. تقدم رؤى حول ولاء العملاء والمبيعات المحتملة."
        },
        {
          "topic": "المقارنات",
          "description": "تتضمن الردود التي تقارن منتج، خدمة، أو تجربة بأخرى، مقدمةً رؤى حول الموقع التنافسي وتفضيلات المستجيب."
        },
        {
          "topic": "المتطلبات أو الاحتياجات",
          "description": "ردود تصف الاحتياجات، الرغبات، أو التوقعات الخاصة من منتج أو خدمة. تساعد هذه الفئة في فهم الفجوة بين ما يُقدم وما يُتوقع."
        },
        {
          "topic": "الأفكار غير المطلوبة",
          "description": "تلتقط الأفكار الابتكارية أو الإبداعية التي لا تندرج بالضرورة ضمن اقتراح مباشر للتحسين ولكنها تقدم اتجاهات جديدة محتملة أو فرص للاستكشاف."
        },
        {
          "topic": "آخر",
          "description": "هذا الفئة تلتقط الردود التي لا تندرج تحت أي من الفئات الأخرى"
        }
      ]

    }
    return intentCategories[lng]
  }

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const successToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'success'})
  }


  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleKeyDown = (event) => {
    const {value} = event.target;
    if (value.length >= 80 && event.key !== 'Backspace') {
      event.preventDefault();
    }
  };

  const submitInferenceRequest = async (dataset, answerVar) => {
    const requestId = generateID();
    const payload = {
      collection_name: dataset,
      answers_column: answerVar,
      max_topics: maxTopics,
      weight: weight,
      target_lang: lng,
      requestId: requestId,
      region: dataRegion||"US"
    }
    try {
      await axios.post(apiPaths.infer_topics_async, payload, status200);
    } catch (error) {
      console.log(error)
      errorToast(`Error inferring topics for Question: ${policy.name}`);
      return null;
    }
    return requestId;
  };

  const handleInferTopics = async (e) => {
    if (e.target.id === 'infer-topics-btn') {
      e.preventDefault()
    }
    if (!dataset || !policy.answerVar) return
    setShowProgressBar(true);
    setIsTopicInferenceLoading(true);
    setTitle("Extracting the relevant topics in your data...");
    setProgress(0);
    const requestId = await submitInferenceRequest(dataset, answerVar)
    const topicsData = await pollTaskProgress(requestId);
    let inferredTopics = topicsData?.topics
    if (inferredTopics) {
      inferredTopics = inferredTopics.sort((a, b) => (a.representativity && b.representativity) ? b.representativity - a.representativity : a.topic.localeCompare(b.topic));
      setTopics(inferredTopics);
      policy.topics = inferredTopics;
      policy.classified = false
      handlePolicyChange(policy);
      successToast("Main topics extracted...");
    }
    // await handleSaveScheme();
    setShowProgressBar(false);
    setProgressMessage('');
    setProgress(0);
    setIsTopicInferenceLoading(false);
  }
  const handleInferSubTopics = async (parentTopic, amountSubtopics) => {
    if (!dataset || !policy.answerVar || !parentTopic) return null;
    setIsLoading(true);
    const payload = {
      collection_name: dataset,
      answers_column: answerVar,
      max_topics: amountSubtopics,
      weight: weight,
      target_lang: lng,
      parent_topic: parentTopic,
      requestId: null
    }
    try {
      let response = await axios.post(apiPaths.infer_subtopics, payload, status200);
      setIsLoading(false);
      let data = response.data;
      let subtopics = null
      if (data && data.topics) {
        subtopics = data.topics.map(subtopic => {
          let st = {...subtopic};
          st.isParent = false;
          st.parent = parentTopic;
          return st;
        });
        successToast("Subtopics extracted...");
      } else {
        errorToast("Error extracting subtopics")
      }

      return subtopics;
    } catch (error) {
      console.log(error)
      errorToast(`Error extracting subtopics for topic: ${parentTopic}`);
    }
    // await handleSaveScheme();
    setIsLoading(false);
    return null;
  }
  const handleFinish = async () => {
    handleNext();
  }

  React.useEffect(() => {
    fillLocalData();
  }, [variables, policy, collection])

  React.useEffect(() => {
    if (enableCustomSegmentations) {
      const predefinedCustomCategories = getPredefinedCustomCategories();
      if (!policy.customSegmentations?.segmentations?.length) {
        policy.customSegmentations = {
          enabled: true,
          segmentations: [
            {
              categories: predefinedCustomCategories,
              label: "Intend of the answer",
              classificationVariable: `${answerVar}_custom_${generateRandomString(10)}_label`,
              classificationPerformed: false
            }
          ],
        };
        setCustomSegmentDefinitions(policy.customSegmentations.segmentations);
        handlePolicyChange(policy);
      }

    }
  }, [lng])

  const switchClassificationMode = () => {
    // Toggle between 'llm' and 'vector'
    const newMode = inferenceMode === 'llm' ? 'vector' : 'llm';
    let localPolicy = _.cloneDeep(policy);
    localPolicy.inferenceMode = newMode;
    localPolicy.classified = false;
    setInferenceMode(newMode);
    handlePolicyChange(localPolicy);
  };

  function generateRandomString(length) {
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let result = '';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

  const switchEnableCustomCategories = () => {
    const enabled = !enableCustomSegmentations;
    let predefinedCustomCategories = enabled ? getPredefinedCustomCategories() : [];
    let localPolicy = _.cloneDeep(policy);
    localPolicy.customSegmentations = {
      enabled: enabled,
      segmentations: enabled ? [
        {
          categories: predefinedCustomCategories,
          label: "Intend of the answer",
          classificationVariable: `${answerVar}_custom_${generateRandomString(10)}_label`,
          classificationPerformed: false
        }
      ] : [],
    }
    setCustomSegmentDefinitions(predefinedCustomCategories);
    setEnableCustomSegmentations(enabled);
    handlePolicyChange(localPolicy);
  }

  const addCustomSegmentation = () => {
    let placeHolderCategories = [];
    for (let i = 0; i < 3; i++) {
      placeHolderCategories.push({
        topic: `Custom category ${i + 1}`,
        description: `Custom category ${i + 1} description`,
        representativity: 0
      })
    }
    policy.customSegmentations.segmentations.push({
      categories: placeHolderCategories,
      label: "Custom segmentation",
      classificationVariable: `${answerVar}_custom_${generateRandomString(10)}_label`,
      classificationPerformed: false
    });
    setCustomSegmentDefinitions(policy.customSegmentations.segmentations);
    handlePolicyChange(policy);
  }
  const submitCustomClassification = async () => {
    const requestId = generateID();
    let sourceClassificationVar = !!policy.parentPolicy ? (policy.parentPolicy.answerVar + "_label") : null;
    if (enableSegmentClassification) {
      sourceClassificationVar = policy.selectedClassificationSegment.propName;
    }
    const payload = {
      collection_name: collection,
      answers_column: policy.answerVar,
      sourceClassificationVariable: sourceClassificationVar,
      parentTopics: !!policy.parentPolicy ? policy.parentPolicy.topics : null,
      topics: policy.topics,
      model: inferenceMode,
      lang: lng,
      requestId: requestId,
      saveClassification: true,
      classification_type: "topics",
      max_topics: maxClassificationTopics,
      region:dataRegion
    };

    try {
      await axios.post(!!sourceClassificationVar ? apiPaths.async_compute_partial_classification : apiPaths.compute_async_classification, payload, status200);
    } catch (error) {
      console.error(error);
      errorToast(`Error computing classification for Question: ${policy.name}`);
    }
    return requestId
  };

  const submitCustomCategoryClassification = async (categories, classification_variable) => {
    const requestId = generateID();
    const payload = {
      collection_name: collection,
      answers_column: policy.answerVar,
      topics: categories,
      model: "llm",
      requestId: requestId,
      saveClassification: true,
      classification_type: "generic",
      classificationVariable: classification_variable,
      region:dataRegion
    };

    try {
      await axios.post(apiPaths.compute_async_classification, payload, status200);
    } catch (error) {
      console.error(error);
      errorToast(`Error computing classification for Question: ${policy.name}`);
    }
    return requestId
  }

  const handleInitializePartialClassification = async (policyClassify) => {
    let requestId = generateID();
    let sourceClassificationVar = policyClassify.parentPolicy?.answerVar + "_label";
    if (enableSegmentClassification) {
      sourceClassificationVar = policyClassify.selectedClassificationSegment.propName;
    }
    if (!sourceClassificationVar) {
      errorToast("Error initializing classification. Please select a segmentation variable or a parent policy.");
      return requestId;
    }
    const payload = {
      collection_name: collection,
      answers_column: policyClassify.answerVar,
      topics: policyClassify.parentPolicy?.topics || [],
      requestId: requestId,
      saveClassification: true,
      classification_type: "topics",
      sourceClassificationVariable: sourceClassificationVar,
      lang: lng,
      max_topics: maxClassificationTopics,
      region:dataRegion
    }

    try {
      const response = await axios.post(apiPaths.initialize_partial_classification, payload, status200);
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData) {
          policyClassify.topics = await updateTopicIcons(responseData.updatedTopics);
          handlePolicyChange(policyClassify);
        }
      }
      // await handleSaveScheme();
    } catch (error) {
      console.error(error);
      errorToast(`Error computing classification for Question: ${policy.name}`);
    }
    return requestId
  }


  const pollTaskProgress = async (requestId) => {
    let countFails = 0
    while (countFails < 10) {
      try {
        const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
        const response_body = progress_response.data;

        if (!response_body || !response_body.data) {
          await new Promise((resolve) => setTimeout(resolve, 1200));
          continue;
        }

        const {message, status, progress, additionalData} = response_body.data;

        if (status === 'failed' || status === 'error') {
          return null;

        } else if (status === 'success' && additionalData) {
          policy.customSegmentations.classificationPerformed = true;
          return additionalData;
        }

        setProgressMessage(`${message}`);
        let norm_progress = Math.min(progress, 100)
        setProgress(norm_progress);

      } catch (e) {
        console.error(e);
        countFails++;
      }
      await new Promise((resolve) => setTimeout(resolve, 1200));
    }
    return null;
  };

  const handleClassifyWithCustomCategories = async (classificationVariable) => {
    setShowProgressBar(true);
    setProgress(0);
    setIsClassificationLoading(true);
    setTitle("Segmenting your data with custom categories...");
    const segment = policy.customSegmentations?.segmentations?.find(segment => segment.classificationVariable === classificationVariable);
    const categories = segment?.categories
    let requestID = await submitCustomCategoryClassification(categories, classificationVariable);
    if (requestID) {
      const representativityData = await pollTaskProgress(requestID)
      const updatedCategories = representativityData?.updatedTopics
      if (updatedCategories) {
        segment.categories = updatedCategories
        segment.classificationPerformed = true;
        setCustomSegmentDefinitions(policy.customSegmentations.segmentations);
        successToast("Custom category successfully classified");
        handlePolicyChange(policy);
        // await handleSaveScheme();
      } else {
        errorToast(`Failed to classify custom category'`)
      }

    }
    setShowProgressBar(false);
    setProgress(0);
    setProgressMessage('');
    setIsClassificationLoading(false);
  }

  const handleClassifyWithTopics = async () => {
    setShowProgressBar(true);
    setProgress(0);
    setIsClassificationLoading(true);
    setTitle("Categorizing your data with topics...");
    let requestID = await submitCustomClassification();
    if (requestID) {
      const representativityData = await pollTaskProgress(requestID)
      const updatedTopics = representativityData?.updatedTopics;
      if (updatedTopics) {
        setTopics(updatedTopics);
        handlePolicyChange({
          ...policy,
          topics: updatedTopics,
          classified: true
        });
        successToast("Question successfully classified");
        // await handleSaveScheme();
      } else {
        errorToast(`Failed to classify '${policy.name}'`)
      }
    }
    setShowProgressBar(false);
    setProgressMessage('');
    setIsClassificationLoading(false);
    setProgress(0);
  }

  const renderIcon = (base64) => {
    const decodedSVG = atob(base64); // Decode base64 to get the SVG content
    return (
      <div
        dangerouslySetInnerHTML={{__html: decodedSVG}}
        style={{
          height: '11px',
          width: '11px',
          display: 'flex',
          alignItems: 'center'
        }}
      />
    );
  }
  const CustomChipContent = ({label, startIcon, endIcon}) => {
    return (
      <Box display="flex" alignItems="center">
        {startIcon}
        <Typography variant="body2" style={{margin: '0 8px', color: 'white'}}>
          {label}
        </Typography>
        {endIcon}
      </Box>
    );
  }

  const handleChipClick = (clickedTopic) => {
    const sorted = [clickedTopic, ...topics.filter(t => t.topic !== clickedTopic.topic)];
    setTopics(sorted);
    setOpen(true);
  };

  const handleTopicMngOpen = () => {
    const sorted = [...topics].sort((a, b) => (b.representativity || 0) - (a.representativity || 0));
    setTopics(sorted);
    setOpen(true)
  };
  const handleTopicMngClose = () => {
    setOpen(false);
    policy.topics = topics.sort((a, b) => (a.representativity && b.representativity) ? b.representativity - a.representativity : a.topic.localeCompare(b.topic));
    setTopics(policy.topics);
    handlePolicyChange(policy);
  };


  // Utility function for color calculation
  const calculateChipColor = (index, length, saturation = 50, lightness = 50) => {
    const hue = (index * 360 / length) % 360;
    return `hsl(${hue}, ${saturation}%, ${lightness}%)`;
  };

// Component for individual topic
  const TopicChip = ({topic, index, isCategoryChip, handleClick}) => {
    const chipColor = calculateChipColor(index, topics.length);
    const categoryChipColor = calculateChipColor(index, customSegmentDefinitions.length);
    return (
      !isCategoryChip ? <Chip
          label={<CustomChipContent
            label={`${topic.topic} ${topic.representativity ? '(' + topic.representativity.toFixed(0) + '%)' : ''}`}
            startIcon={topic.icon ? renderIcon(topic.icon) : null}
            endIcon={topic.subtopics?.length > 0 ? <CallSplit
              style={{transform: "scaleY(-1)", color: "black"}}/> : null}
          />}
          variant="outlined"
          style={{
            maxWidth: '100%',
            overflow: 'visible',
            backgroundColor: chipColor
          }}
          onClick={() => handleClick(topic)}
        /> :
        <Chip
          label={<CustomChipContent
            label={`${topic.topic} ${topic.representativity ? '(' + topic.representativity.toFixed(0) + '%)' : ''}`}
          />}
          variant="outlined"
          style={{
            maxWidth: '100%',
            overflow: 'visible',
            backgroundColor: categoryChipColor
          }}
        />
    );
  };


// Component for subtopics
  const SubtopicChip = ({subtopic, parentIndex, subIndex, handleClick}) => {
    const chipColor = calculateChipColor(parentIndex, topics.length, 50, 55 + (5 * (subIndex + 1)));

    return (
      <Chip
        label={<CustomChipContent
          label={`${subtopic.topic} ${subtopic.representativity ? '(' + subtopic.representativity.toFixed(0) + '%)' : ''}`}
          startIcon={subtopic.icon ? renderIcon(subtopic.icon) : null}
        />}
        variant="outlined"
        style={{
          maxWidth: '100%',
          overflow: 'visible',
          backgroundColor: chipColor
        }}
        onClick={() => handleClick(subtopic)}
      />
    );
  };

  const TooltipTitle = ({topic, index, isCategoryTopic = false}) => {
    if (isCategoryTopic) return <span>{topic.topic}</span>;
    if (!topic.subtopics || topic.subtopics.length === 0) {
      return <span>{topic.topic}</span>;
    }

    return (
      <div style={{color: 'inherit'}}>
        <Typography variant="body2"
                    style={{fontWeight: 'bold', color: 'inherit'}}>
          {topic.topic}:
        </Typography>
        {topic.subtopics.map((subtopic, subIndex) => (
          <Typography key={`typo-sub-${index}-${subIndex}`} variant="inherit"
                      style={{color: 'inherit'}}>
            - {subtopic.topic}
          </Typography>
        ))}
      </div>
    );
  };

  function renderTopicChips() {
    if (topics.length === 0) {
      return <Typography variant="body2" style={{color: 'black'}}>No topics to
        display</Typography>;
    }

    return (
      <>
        {topics.map((topic, index) => (
          <div key={`topic-${index}`}>
            <Tooltip title={<TooltipTitle topic={topic} index={index}/>}>
              <div key={`tt-wrapper-${index}`}>
                <TopicChip topic={topic} index={index}
                           isCategoryChip={false}
                           handleClick={handleChipClick}/>
              </div>
            </Tooltip>
            {topic.subtopics?.length > 0 && (
              <Box display="flex" flexWrap="wrap" gap={1}
                   style={{marginLeft: '20px'}}>
                {topic.subtopics.map((subtopic, subIndex) => (
                  <Tooltip title={`${topic.topic}: ${subtopic.topic}`}
                           key={`tooltip-sub-${index}-${subIndex}`}>
                    <div key={`tt-wrapper-sub-${index}-${subIndex}`}>
                      <SubtopicChip subtopic={subtopic} parentIndex={index}
                                    subIndex={subIndex}
                                    handleClick={handleChipClick}/>
                    </div>
                  </Tooltip>
                ))}
              </Box>
            )}
          </div>
        ))}
      </>
    );
  }

// Stepper Form Children
  const steps = [
    {
      label: `${t('survey_question')}:`,
      visible: true,
      description: (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Item>
              <FormControl fullWidth variant="outlined">
                <TextField
                  label={policy.name ? t('survey_placeholder') : ""}
                  id="policy_name"
                  defaultValue={policy.name || ''}
                  placeholder={t('survey_placeholder')}
                  variant="outlined"
                  onChange={(e) => {
                    policy.name = e.target.value
                    handlePolicyChange(policy)
                  }}
                  inputProps={{maxLength: 80}}
                  onKeyDown={handleKeyDown}
                />
              </FormControl>
            </Item>
            <Item>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      select
                      label={t('answer_variable_placeholder')}
                      value={answerVar}
                      placeholder={t('answer_variable_placeholder')}
                      onChange={(e) => {
                        setAnswerVar(e.target.value);
                        policy.answerVar = e.target.value;
                        policy.classified = false;
                        policy.topics = [];
                        handlePolicyChange(policy);
                      }}
                      variant="outlined"
                      id="answer_variable_selection">
                      {answerVars.map((field) => (
                        <MenuItem key={field.propName} value={field.propName}>
                          {field.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </Item>
            <Item>
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    <TextField
                      select
                      label={(t('audio_variable_placeholder') || 'Audio variable')}
                      value={audioVar}
                      placeholder={(t('audio_variable_placeholder') || 'Audio variable')}
                      onChange={(e) => {
                        setAudioVar(e.target.value);
                        policy.audioVar = e.target.value;
                        handlePolicyChange(policy);
                      }}
                      variant="outlined"
                      id="answer_audio_variable_selection">
                      <MenuItem key="none" value="">
                        None
                      </MenuItem>
                      {audioVars.map((field) => (
                        <MenuItem key={field.propName} value={field.propName}>
                          {field.label}
                        </MenuItem>
                      ))}
                    </TextField>
                  </FormControl>
                </Grid>
              </Grid>
            </Item>
            <Item>
              <Grid container spacing={1}>
                <Grid item style={{display: 'flex', alignItems: 'center'}}>
                  <Typography
                    style={{fontFamily: "Montserrat", marginRight: 8}}>
                    Enable segments classification
                  </Typography>
                  <Switch
                    checked={!!enableSegmentClassification}
                    onChange={() => {
                      let updatedPolicy = _.clone(policy);
                      updatedPolicy.enabledSegmentClassification = !enableSegmentClassification
                      setEnableSegmentClassification(prevState => {
                        return !prevState
                      });
                      updatedPolicy.parentPolicy = null;
                      updatedPolicy.selectedClassificationSegment = null;

                      updatedPolicy.topics = [];
                      setParentPolicy(null);
                      setTopics([]);
                      handlePolicyChange(updatedPolicy);
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControl fullWidth variant="outlined">
                    {enableSegmentClassification ? (
                      <TextField
                        select
                        label={(t('classification_segment') || 'Classification Segment')}
                        value={selectedClassificationSegment?.propName || ""}
                        placeholder={(t('classification_segment') || 'Classification Segment')}
                        onChange={async (e) => {
                          const updatedPolicy = _.clone(policy);
                          if (e.target.value) {
                            let selectedVariable = variables.find(v => v.propName === e.target.value);
                            updatedPolicy.selectedClassificationSegment = selectedVariable;
                            setSelectedClassificationSegment(selectedVariable);
                            updatedPolicy.parentPolicy = null;
                            updatedPolicy.topics = [];
                            setParentPolicy(null);
                            setTopics([]);
                            setIsLoading(true);
                            await handleInitializePartialClassification(updatedPolicy);
                            setIsLoading(false);
                          } else {
                            updatedPolicy.parentPolicy = null;
                            updatedPolicy.selectedClassificationSegment = null;
                            updatedPolicy.enabledSegmentClassification = false;
                            setEnableSegmentClassification(false);
                            setSelectedClassificationSegment(null);
                            updatedPolicy.topics = [];
                            setParentPolicy(null);
                            setTopics([]);
                            handlePolicyChange(updatedPolicy);
                          }
                        }}
                        variant="outlined"
                        id="parent-answer-selection">
                        <MenuItem key="none" value={""}>
                          None
                        </MenuItem>
                        {segmentationCandidates.map((segmentCandidate) => (
                          <MenuItem key={segmentCandidate.propName}
                                    value={segmentCandidate.propName}>
                            {segmentCandidate.label || segmentCandidate.propName}
                          </MenuItem>
                        ))}
                      </TextField>
                    ) : (
                      <TextField
                        select
                        label={(t('parent_question') || 'Parent question')}
                        value={parentPolicy?.name || ""}
                        placeholder={(t('parent_question') || 'Parent question')}
                        onChange={async (e) => {
                          if (e.target.value) {
                            const localParentPolicy = parentCandidates.find(p => p.name === e.target.value);
                            policy.parentPolicy = _.cloneDeep(localParentPolicy);
                            setParentPolicy(localParentPolicy);
                            setIsLoading(true);
                            await handleInitializePartialClassification(policy);

                            setIsLoading(false);
                          } else {
                            policy.parentPolicy = null;
                            policy.topics = [];
                            setParentPolicy(null);
                            setTopics([]);
                            handlePolicyChange(policy);
                          }
                        }}
                        variant="outlined"
                        id="parent-answer-selection">
                        <MenuItem key="none" value={""}>
                          None
                        </MenuItem>
                        {parentCandidates.map((parentPolicy) => (
                          <MenuItem key={parentPolicy.id}
                                    value={parentPolicy.name}>
                            {parentPolicy.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    )}
                  </FormControl>
                </Grid>
              </Grid>
            </Item>
          </Grid>
        </Grid>
      ),
      dataCy: 'step-policy-name',
    },
    {
      label: t('discussed_topics'),
      dataCy: 'step-topic-inference',
      visible: true,
      description: (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column'}}
              id="container-topic-inference">  {/* Main Container */}
          {/* Autocomplete Grid */}
          <Grid item xs={12} id="container-topic-autocomplete"
                style={{width: "100%", flex: 1}}>
            <Box display="flex" flexWrap="wrap" gap={1} flexDirection="column"
                 alignItems="flex-start"> {/* flexDirection="column" alignItems="flex-start" */}
              {renderTopicChips()}
            </Box>
          </Grid>
          {/* Textfield for Amount of Topics */}
          <Grid container item xs={12} spacing={3}
                id="container-topic-controls">
            <Grid item xs={12} style={{marginTop: 10}}>
              <FormControl variant="outlined">
                <TextField
                  style={{marginTop: 10}}
                  label={t('amount_topics')}
                  id="referenced_topics_amount"
                  value={maxTopics}
                  type={'number'}
                  variant="outlined"
                  onChange={(e) => {
                    setMaxTopics(parseInt(e.target.value))
                  }}
                  InputProps={{inputProps: {min: 1}}}
                  onKeyDown={(event) => {
                    if (event?.key === '-' || event?.key === '+') {
                      event.preventDefault();
                    }
                  }}
                />
              </FormControl>
            </Grid>

            {/* Buttons Grid */}
            <Grid container item xs={12} spacing={1}>

              {/* Infer Topics Button */}
              <Grid item xs={6}>
                <FormControl variant="outlined">
                  <Button
                    color='secondary'
                    variant="contained"
                    id="infer-topics-btn"
                    onClick={(e) => handleInferTopics(e)}>
                    {t('infer_topics')}
                  </Button>
                </FormControl>
              </Grid>

              {/* Manage Topics Button (ModalDialogTopicsEdit) */}
              <Grid item xs={6}>
                <div>
                  <Button onClick={handleTopicMngOpen} variant="contained"
                          style={{width: '100%'}}>{t('edit_topics')}</Button>
                  {(open) && (<ModalDialogTopicsEdit
                    topics={topics}
                    isCategoriesCustom={false}
                    onCategoriesChange={(topics) => {
                      let localTopics = JSON.parse(JSON.stringify(topics));
                      policy.topics = localTopics;
                      policy.classified = false;
                      computeTopicCoverages(localTopics);
                    }}
                    inferSubTopics={handleInferSubTopics}
                    handleClose={handleTopicMngClose}
                    policyName={policy.name}
                  />)}
                </div>
              </Grid>
            </Grid>
          </Grid>

        </Grid>

      )
    },
    {
      label: t('answer_classification'),
      dataCy: 'step-classification-mode',
      visible: true,
      description: (
        <>
          <Grid container flexDirection="column" spacing={2}>
            <Grid item>
              <Typography variant="body1">
                {t('select_classification_mode')}
              </Typography>
            </Grid>
            <Grid container item flexDirection="row" alignItems="center"
                  spacing={1}>
              <Grid item>
                <Switch
                  checked={inferenceMode === 'llm'}
                  onChange={switchClassificationMode}
                  color="primary"
                  aria-labelledby={"switch-label"}
                />
              </Grid>
              <Grid item>
                <Typography id={"switch-label"} variant="body2">
                  {inferenceMode}
                </Typography>
              </Grid>
            </Grid>
            <Grid container item flexDirection={"column"} spacing={3}>
              <Grid item>
                <Typography id={"non-linear-slider"} variant="body1">
                  {t('amount_samples')}:
                </Typography>
              </Grid>
              <Grid item>
                <Slider
                  value={amountSamples}
                  min={5}
                  max={20}
                  step={1}
                  style={{width: 100, marginTop: 3, marginLeft: 5}}
                  onChange={(event, value) => {
                    setAmountSamples(value);
                    policy.amountSamples = value;
                    handlePolicyChange(policy);
                  }}
                  valueLabelDisplay="auto"
                  aria-labelledby="non-linear-slider"
                />
              </Grid>
            </Grid>
            {(!parentPolicy) && (
              <Grid container item flexDirection={"column"} spacing={3}>
                <Grid item>
                  <Typography id='max-classification-topics' variant="body1">
                    {t('Max. topics per sample') || "Max. topics per sample"}:
                  </Typography>
                </Grid>

                <Grid item>
                  <Slider
                    value={maxClassificationTopics}
                    min={1}
                    max={Math.round(topics.length / 2)}
                    step={1}
                    style={{width: 100, marginTop: 3, marginLeft: 5}}
                    onChange={(event, value) => {
                      setMaxClassificationTopics(value);
                      policy.maxClassificationTopics = value;
                      handlePolicyChange(policy);
                    }}
                    valueLabelDisplay="auto"
                    aria-labelledby="max-classification-topics"
                  />
                </Grid>

              </Grid>
            )}
            <Grid item>
              <FormControl>
                <Button
                  color='secondary'
                  variant="contained"
                  onClick={async () => {
                    await handleClassifyWithTopics();
                  }}>
                  {t('classify')}
                </Button>
              </FormControl>
            </Grid>
          </Grid>
        </>
      )
    },
    {
      label: t('custom_segmentation'),
      dataCy: 'step-category-section',
      visible: true,
      description: (
        <Grid container spacing={2}
              style={{display: 'flex', flexDirection: 'column'}}
              id="container-category-section">
          <Grid container item flexDirection="row" alignItems="center"
                spacing={1}>
            <Grid item>
              <Switch
                checked={enableCustomSegmentations}
                onChange={() => switchEnableCustomCategories()}
                color="primary"
                aria-labelledby={"category-switch-label"}
              />
            </Grid>
            <Grid item>
              <Typography id={"category-switch-label"} variant="body2">
                Define custom segments
              </Typography>
            </Grid>
          </Grid>
          <Grid item container spacing={2} direction={'column'}>
            {
              enableCustomSegmentations ? (
                customSegmentDefinitions.map((segment, index) => {
                  return (
                    <CustomSegmentation
                      key={`custom-segmentation-cmp-${index}`}
                      segment={segment}
                      segmentIndex={index}
                      handleDeleteSegment={(deleteIndex) => {
                        const filteredSegments = customSegmentDefinitions.filter((segment, i) => i !== deleteIndex);
                        setCustomSegmentDefinitions(filteredSegments);
                        if (filteredSegments.length === 0) {
                          setEnableCustomSegmentations(false);
                          policy.customSegmentations.enabled = false;
                        }
                        policy.customSegmentations.segmentations = filteredSegments;
                        handlePolicyChange(policy);
                      }}
                      handleUpdateSegment={(newSegment, index) => {
                        setCustomSegmentDefinitions(customSegmentDefinitions.map((segment, i) => i === index ? newSegment : segment));
                        policy.customSegmentations.segmentations = customSegmentDefinitions.map((segment, i) => i === index ? newSegment : segment);
                        handlePolicyChange(policy);
                      }}
                      handleClassification={(clasificationVariable) => handleClassifyWithCustomCategories(clasificationVariable)}
                    />
                  )
                })
              ) : null
            }
            {(enableCustomSegmentations) && (
              <Grid item>
                <Button variant="contained" onClick={addCustomSegmentation}>Add
                  segment</Button>
              </Grid>
            )}
          </Grid>
        </Grid>

      )
    },
    {
      label: 'Topic images',
      dataCy: 'step-image-section',
      visible: true,
      description: (
        <TopicImageManager policy={policy} dataset={dataset}
                           answerVar={answerVar}
                           updatePolicy={(updatedPolicy) => {
                             const localPolicy = _.cloneDeep(policy);
                             console.log("Updated policy", updatedPolicy);
                             setTopics(updatedPolicy.topics);
                             localPolicy.topics = updatedPolicy.topics;
                             localPolicy.imageSettings = updatedPolicy.imageSettings;
                             handlePolicyChange(localPolicy);
                           }}/>
      )
    }

  ];

  return (
    <>
      <Box sx={{flexGrow: 1}} style={{height: 'auto'}}>
        <Stepper activeStep={activeStep} nonLinear orientation="vertical">
          {steps.filter(step => step.visible).map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                data-cy={step.dataCy}
                onClick={handleStep(index)}
                style={{cursor: 'pointer'}}
                optional={
                  index === 4
                    ? (<Typography variant="caption">Last step</Typography>)
                    : null
                }>
                {step.label}
              </StepLabel>
              <StepContent>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    <Box>{step.description}</Box>
                  </Grid>
                  <Grid container item xs={12} justifyContent="flex-start">
                    <Button
                      color='secondary'
                      variant="contained"
                      onClick={index === steps.length - 1 ? handleFinish : handleNext}>
                      {index === steps.length - 1 ? t('finish') : t('continue')}
                    </Button>
                    <Button
                      style={{marginLeft: 6}}
                      variant="contained"
                      disabled={index === 0}
                      onClick={handleBack}>
                      {t('go_back')}
                    </Button>
                  </Grid>
                </Grid>
              </StepContent>
            </Step>
          ))}
        </Stepper>
        {activeStep === steps.length && (
          <Paper square elevation={0} sx={{p: 3}}>
            <Typography>All steps completed</Typography>
          </Paper>
        )}
      </Box>
    </>
  );
}
