import * as React from 'react';
// Form Stepper
import StepForm from '../../stepper/StepForm';
import useScheme from '../../../../../api/hooks/useScheme';
import { useLocation } from 'react-router-dom';
import { useAuth } from '../../../../providers/AuthProvider';
import {
  setTargetCollection, setTargetScheme
} from '../../../../../store/appSlice';
import { useDispatch } from 'react-redux';
import useSchemes from '../../../../../api/hooks/useSchemes';
import { SnackbarProvider } from 'notistack';
import {Grid} from '@mui/material'
// import { Grid } from '@material-ui/core';


const Specification = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const orgId = location.pathname.split('/')[2] === 'workspace'
    ? user.id
    : location.pathname.split('/')[2];
  const isWorkspace = location.pathname.split('/')[2] === 'workspace'
  // const [isLoading, setIsLoading] = React.useState(false)

  const scenario = useScheme({
    user_id: orgId,
    scheme_id: location.pathname.split('/')[4],
    enabled: location.pathname.split('/')[4] !== 'create'
  });
  const schemes = useSchemes(orgId)

  React.useEffect(() => {
    if (scenario.data) {
      dispatch(setTargetCollection(scenario.data.collection))
      dispatch(setTargetScheme(scenario.data.scheme))

      if (scenario.data.scheme && scenario.data.scheme.id) {
        // setIsLoading(false)
      }
    }
  }, [scenario.data])

  React.useEffect(() => {
    // setIsLoading(false)
    if (location.pathname.split('/')[4] !== 'create') {
      // setIsLoading(true)
    }
  }, [])

  React.useEffect(() => {
    if (location.pathname.split('/')[4] === 'create') {
      // setIsLoading(schemes.isLoading)
    }
  }, [schemes.isLoading])

  return (
    <SnackbarProvider maxSnack={10}>
      <Grid container justifyContent='center' spacing={1}>
        {(scenario.data && schemes.data) &&
          <StepForm scenario={scenario.data}
            schemes={schemes.data}
            orgId={orgId}
            isWorkspace={isWorkspace} />
        }
      </Grid>
    </SnackbarProvider>
  );
};

export default Specification;
