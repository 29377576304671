import {
  Grid,
  IconButton,
  InputAdornment,
  Modal,
  Paper,
  TextField,
  Typography
} from '@mui/material'
import {useEffect, useState} from 'react';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import UndoIcon from '@mui/icons-material/Undo';
import SplitIcon from '@mui/icons-material/CallSplit';
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import {MuiBox, MuiSplitModal} from './styles/topicCard';
import Tooltip from "@mui/material/Tooltip";

export const TopicCard = ({
                            id,
                            topic,
                            index,
                            handleEditCard,
                            handleDeleteCard,
                            splitTopic,
                            isCategoriesCustom
                          }) => {
  const [localTopic, setLocalTopic] = useState({
    topic: "",
    description: "",
    representativity: 0,
    isParent: true
  });
  const [isEditMode, setIsEditMode] = useState(false);
  const [isSplitModalOpen, setIsSplitModalOpen] = useState(false);
  const [splitValue, setSplitValue] = useState(3);


  const handleChangeTopic = (event) => {
    let editedTopic = {...localTopic};
    editedTopic.topic = event.target.value;
    setLocalTopic(editedTopic);
  }

  const handleDescriptionChange = (event) => {
    let editedTopic = {...localTopic};
    editedTopic.description = event.target.value;
    setLocalTopic(editedTopic);
  }

  const handleUndoTopic = () => {
    let editedTopic = {...localTopic};
    editedTopic.topic = topic.topic;
    setLocalTopic(editedTopic);
  }

  const handleUndoDescription = () => {
    let editedTopic = {...localTopic};
    editedTopic.description = topic.description;
    setLocalTopic(editedTopic);
  }

  const handleEdit = () => {
    if (isEditMode) {
      handleEditCard(localTopic, index);
    }
    setIsEditMode((prevState) => !prevState);
  }
  const handleDelete = () => {
    handleDeleteCard(localTopic, index);
  }

  useEffect(() => {
    setLocalTopic(topic);
  }, [topic, index])

  const SplitModal = () => {
    return (
      <Modal
        open={isSplitModalOpen}
        onClose={() => setIsSplitModalOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <MuiSplitModal
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400, // or whatever width you want
            bgcolor: 'background.paper',
            border: '2px solid #000',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6">
            Specify Number of Subtopics
          </Typography>
          <TextField
            id="modal-description"
            type="number"
            value={splitValue}
            aria-valuemin={2}
            onChange={(e) => setSplitValue(parseInt(e.target.value))}
            label="Number of Subtopics"
            variant="outlined"
            fullWidth
            margin="normal"
          />
          <IconButton
            sx={{
              backgroundColor: theme => theme.palette.secondary.main,
              color: 'inherit',/*marginTop:3.5,marginLeft:0.5*/
            }}
            onClick={() => {
              if (splitValue > 2) {
                splitTopic(localTopic.topic, splitValue);
                setIsSplitModalOpen(false);
              }
            }}
          >
            <CheckIcon/>
          </IconButton>
          {/*</Box>*/}
        </MuiSplitModal>
      </Modal>
    )
  }
  return (
    <Tooltip
      title={`${topic?.topic || localTopic.topic} ${topic.count || ''} (${topic?.representativity || 0}%)`}>
      <MuiBox
        alignItems={'center'}
        display={'flex'}
        flexDirection={'column'}
        justifyContent={'space-between'}
        data-handler-id={id}>
        <Grid container alignItems='center'
              spacing={localTopic.isParent ? 2 : 1}>
          <Grid item xs={4}>
            {isEditMode ? (
                <TextField
                  label="Topic"
                  type='text'
                  onChange={handleChangeTopic}
                  value={localTopic.topic}
                  variant="outlined"
                  fullWidth
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={handleUndoTopic}>
                          <UndoIcon/>
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              ) :
              (
                <Paper elevation={0} style={{padding: '6px 12px'}}>
                  <Typography variant="body1"
                              color="textSecondary">Topic</Typography>
                  <Typography variant="body1">{localTopic.topic}</Typography>
                </Paper>
              )
            }
          </Grid>
          <Grid item xs={6}>
            {
              isEditMode ? (
                  <TextField
                    label="Description"
                    type='text'
                    onChange={handleDescriptionChange}
                    value={localTopic.description}
                    fullWidth
                    variant='outlined'
                    multiline
                    minRows={1}
                    maxRows={3}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton edge="end" onClick={handleUndoDescription}>
                            <UndoIcon/>
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) :
                (<Paper elevation={0} style={{padding: '6px 12px'}}>
                  <Typography variant="body1"
                              color="textSecondary">Description</Typography>
                  <Typography
                    variant="body1">{localTopic.description}</Typography>
                </Paper>)
            }
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleEdit}>
              {isEditMode ? <SaveIcon/> : <EditIcon/>}
            </IconButton>
            <IconButton onClick={handleDelete}>
              <DeleteIcon/>
            </IconButton>
          </Grid>
        </Grid>
        {(localTopic.isParent && !isCategoriesCustom) && (
          <IconButton onClick={() => setIsSplitModalOpen(true)}>
            <SplitIcon style={{transform: "scaleY(-1)"}}/>
          </IconButton>
        )}
        <SplitModal/>
      </MuiBox>
    </Tooltip>
  )
}
