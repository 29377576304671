import React, {useState} from 'react';
import {Tabs} from 'antd';
import Policy from './form/Policy';
import {v4 as uuidv4} from 'uuid';
import {getNormalPolicy} from '../../../../../utils/template';
import {useDispatch, useSelector} from 'react-redux';
import {setActivePoliciesTab,} from '../../../../../store/appSlice';
import {DndContext, PointerSensor, useSensor} from '@dnd-kit/core';
import {
  arrayMove, horizontalListSortingStrategy, SortableContext, useSortable
} from '@dnd-kit/sortable';
import * as _ from 'lodash';
import {CSS} from '@dnd-kit/utilities';
import {css} from '@emotion/css';
import {Paper} from "@mui/material";
import {generateID} from "../../../../../utils/utils";
import axios from "../../../../../api/axios/axiosInstance";
import apiPaths from "../../../../../api/apiPaths";
import {status200} from "../../../../../api/status.utils";
import {enqueueSnackbar} from "notistack";
import {useTranslation} from "../../../../providers/TranslationProvider";
import {useLoading} from "../../../../providers/LoadingProvider";

const DraggableTabNode = ({className, onActiveBarTransform, ...props}) => {
  const {
    attributes, listeners, setNodeRef, transform, transition, isSorting
  } = useSortable({
    id: props['data-node-key'],
  });

  const style = {
    ...props.style,
    transform: CSS.Transform.toString(transform),
    transition,
    cursor: 'move',
  };

  React.useEffect(() => {
    if (!isSorting) {
      onActiveBarTransform('');
    } else if (className?.includes('ant-tabs-tab-active')) {
      onActiveBarTransform(css`
        .ant-tabs-ink-bar {
          transform: ${CSS.Transform.toString(transform)};
          transition: ${transition} !important;
        }
      `,);
    }
  }, [className, isSorting, transform]);

  return React.cloneElement(props.children, {
    ref: setNodeRef, style, ...attributes, ...listeners,
  });
};

const Policies = ({
                    policies,
                    dataRegion,
                    variables,
                    setPolicies,
                    dataset,
                    weight,
                    handleSaveScheme
                  }) => {
  const initialItems = [];
  const [activeKey, setActiveKey] = useState(0);
  const [items, setItems] = useState(initialItems);
  const dispatch = useDispatch()
  const activeTab = useSelector(state => state.app.activePoliciesTab)
  const {
    setTitle, setIsLoading, setShowProgressBar, setProgressMessage, setProgress
  } = useLoading();
  const {lng} = useTranslation();

  const onChange = (newActiveKey) => {
    setActiveKey(newActiveKey);
    dispatch(setActivePoliciesTab(newActiveKey))
  };

  const add = () => {
    const id = uuidv4();
    let allPolicies = [...policies];
    let newPolicy = {
      id,
      name: "Question " + (allPolicies.length + 1),
      answerVar: '',
      audioVar: null,
      tree_state: null,
      parentPolicy: null,
      pos: items.length + 1,
      type: 'normal',
      topics: [],
      classified: false,
      segmentation_variables: {
        in_dataset: [], custom: null,
      },
    };

    if (policies[0]) {
      newPolicy = Object.assign({}, policies[0], newPolicy);
    } else {
      newPolicy = getNormalPolicy(newPolicy)
    }

    allPolicies.push(newPolicy);
    setPolicies(allPolicies);
  };

  const remove = (targetKey) => {
    let allPolicies = [...policies];
    let newActiveKey = activeKey;
    let lastIndex = -1;
    items.forEach((item, i) => {
      if (item.key === targetKey) {
        lastIndex = i - 1;
      }
    });
    allPolicies = allPolicies.filter((item) => item.id !== targetKey);
    const newPanes = items.filter((item) => item.key !== targetKey);
    if (newPanes.length && newActiveKey === targetKey) {
      if (lastIndex >= 0) {
        newActiveKey = newPanes[lastIndex].key;
      } else {
        newActiveKey = newPanes[0].key;
      }
    }
    setPolicies(allPolicies);
    setItems(newPanes);
    setActiveKey(newActiveKey);
  };

  const onEdit = (targetKey, action) => {
    if (action === 'add') {
      add();
    } else {
      remove(targetKey);
    }
  };

  const handlePolicyChange = (pol) => {
    console.log("New Policy", pol);
    const policyIndex = policies.findIndex(p => p.id === pol.id)
    let localPolicies = [...policies]
    const subordinates = localPolicies.filter(p => p.parentPolicy !== null && p.parentPolicy?.id === pol.id && !_.isEqual(p.parentPolicy.topics.map(t => ({
      topic: t.topic,
      image_url: t.image_url,
      description: t.description,
      image_description: t.image_description,
      count: t.count
    })), pol.topics.map(t => ({
      topic: t.topic,
      image_url: t.image_url,
      description: t.description,
      image_description: t.image_description,
      count: t.count
    }))));

    localPolicies[policyIndex] = pol;
    setPolicies(localPolicies);
    if (subordinates?.length > 0) {
      console.log("Change detected in topics")
      console.log("Found subordinate policies", subordinates)
      updateSubordinatePolicies(subordinates, localPolicies, pol);
    }
  }

  const errorToast = (msg) => {
    enqueueSnackbar(msg, {variant: 'error'})
  }

  const updateSubordinatePolicies = async (subordinates, localPolicies, updatedParent) => {
    setShowProgressBar(false);
    setIsLoading(true);
    setTitle("Categorizing dependent questions");
    setProgress(0);
    setProgressMessage("This will only take a few seconds...")
    for (const subordinate of subordinates) {
      const localPolicyIdx = localPolicies.findIndex(lp => lp.id === subordinate.id);
      if (localPolicyIdx >= 0 && !_.isEqual(subordinate.parentPolicy.topics.map(t => ({
        ...t, subtopics: []
      })), updatedParent.topics.map(t => ({...t, subtopics: []})))) {
        subordinate.parentPolicy = updatedParent;
        subordinate.topics = await reInitializePartialClassification(subordinate);
        localPolicies[localPolicyIdx] = subordinate;
      }
    }
    setIsLoading(false);
    setTitle('');
    setProgressMessage("");
    return localPolicies;
  }

  const reInitializePartialClassification = async (policyClassify) => {
    let requestId = generateID();
    let sourceClassificationVar = policyClassify.parentPolicy?.answerVar + "_label";
    if (policyClassify.selectedClassificationSegment?.propName) {
      sourceClassificationVar = policyClassify.selectedClassificationSegment.propName;
    }
    if (!sourceClassificationVar) {
      errorToast("Error initializing classification. Please select a segmentation variable or a parent policy.");
      return policyClassify.topics;
    }
    const payload = {
      collection_name: dataset,
      answers_column: policyClassify.answerVar,
      topics: policyClassify.parentPolicy?.topics || [],
      requestId: requestId,
      saveClassification: true,
      classification_type: "topics",
      sourceClassificationVariable: sourceClassificationVar,
      lang: lng,
      region: dataRegion||"US",
    }

    try {
      const response = await axios.post(apiPaths.initialize_partial_classification, payload, status200);
      if (response.status === 200) {
        const responseData = response.data;
        if (responseData) {
          return responseData.updatedTopics;
        }
      }
    } catch (error) {
      console.error(error);
      errorToast(`Error computing classification for Question: ${policyClassify.name}`);
    }
    return policyClassify.topics
  }

  React.useEffect(() => {
    let firstKey = null;
    for (const policy of policies) {
      initialItems.push({
        label: policy.name, children: (<Policy
          policy={policy}
          dataRegion={dataRegion}
          parentCandidates={policies.filter(p => p.id !== policy.id)}
          variables={variables}
          handlePolicyChange={(p) => handlePolicyChange(p)}
          collection={dataset}
          weight={weight}
          key={policy.id}
          handleSaveScheme={handleSaveScheme}
        />), key: String(policy.id),
      })

      if (firstKey === null) {
        firstKey = policy.id
      }
    }

    setItems(initialItems);
    setActiveKey(String(activeTab ? activeTab : firstKey))
  }, [policies])

  const [className, setClassName] = useState('');

  const sensor = useSensor(PointerSensor, {activationConstraint: {distance: 10}});

  const onDragEnd = ({active, over}) => {
    if (active.id !== over?.id) {
      let localPolicies = [...policies];
      const activeIndex = localPolicies.findIndex((i) => i.id === active.id);
      const overIndex = localPolicies.findIndex((i) => i.id === over?.id);
      setPolicies(arrayMove(localPolicies, activeIndex, overIndex));
    }
  };

  return (<Paper style={{borderTop: 'none', padding: "10px"}}>
    <Tabs
      className={className}
      style={{width: '100%', height: 'auto'}}
      type="editable-card"
      onChange={onChange}
      activeKey={activeKey}
      onEdit={onEdit}
      items={items}
      renderTabBar={(tabBarProps, DefaultTabBar) => (
        <DndContext sensors={[sensor]} onDragEnd={onDragEnd}>
          <SortableContext items={items.map((i) => i.key)}
                           strategy={horizontalListSortingStrategy}>
            <DefaultTabBar {...tabBarProps}>
              {(node) => (<DraggableTabNode
                {...node.props}
                key={node.key}
                onActiveBarTransform={setClassName}
              >
                {node}
              </DraggableTabNode>)}
            </DefaultTabBar>
          </SortableContext>
        </DndContext>)}/>
  </Paper>);
};

export default Policies;
