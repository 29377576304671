import * as React from 'react';

import Button from '@mui/material/Button';
import Modal from 'components/common/Modal';

import {TopicCard} from '../dragdrop/TopicCard';

import {Box, Button as MuiButton, Grid, IconButton} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

import {useTranslation} from '../../../../../providers/TranslationProvider';
import ExportIcon from '@mui/icons-material/Download';
import ImportIcon from '@mui/icons-material/Upload';
import SaveIcon from '@mui/icons-material/Save';

const exportTopics = (topics, policyName = '') => {
  const jsonData = JSON.stringify(topics, null, 2);
  const blob = new Blob([jsonData], {type: 'application/json'});
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${policyName}_topics.json`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

export default function ModalDialogTopicsEdit({
                                                topics,
                                                isCategoriesCustom,
                                                label,
                                                onCategoriesChange,
                                                inferSubTopics,
                                                handleClose,
                                                policyName = ""
                                              }) {
  const {t} = useTranslation()

  const [topicsLocal, setTopicsLocal] = React.useState([]);

  const renderCard = (topic, index) => {
    return !topic.subtopics || topic.subtopics.length === 0 ? (
      <TopicCard
        key={topic.topic + index}
        index={index}
        id={topic.topic}
        topic={topic}
        handleEditCard={handleEditCard}
        handleDeleteCard={handleDeleteCard}
        splitTopic={handleSplit}
        isCategoriesCustom={isCategoriesCustom}
      />
    ) : (
      <>
        <TopicCard
          key={topic.topic + index}
          index={index}
          id={topic.topic}
          topic={topic}
          handleEditCard={handleEditCard}
          handleDeleteCard={handleDeleteCard}
          splitTopic={handleSplit}
          isCategoriesCustom={isCategoriesCustom}
        />
        <div style={{marginLeft: '40px'}}>
          {topic.subtopics.map((subtopic, subtopicIndex) => {
            return (<TopicCard
              key={subtopic.topic + subtopicIndex}
              index={subtopicIndex}
              id={subtopic.topic}
              topic={subtopic}
              handleEditCard={handleEditCard}
              handleDeleteCard={handleDeleteCard}
            />);
          })
          }
        </div>
      </>
    )
  }

  const handleSubmit = () => {
    onCategoriesChange(topicsLocal);
    setTopicsLocal([]);
    handleClose();
  }

  const handleEditCard = (topic, index) => {
    setTopicsLocal(prevTopicsLocal => {
      return prevTopicsLocal.map((currentTopic, currentIndex) => {
        if (topic.isParent) {
          return currentIndex === index ? topic : currentTopic;
        } else {
          if (currentTopic.isParent && currentTopic.topic === topic.parent) {
            currentTopic.subtopics?.forEach((subtopic, subtopicIndex) => {
              if (subtopic.topic === topic.topic) {
                currentTopic.subtopics[subtopicIndex] = topic;
              }
            });
          }
          return currentTopic;
        }
      });
    });
  }

  const handleDeleteCard = (localTopic, index) => {
    if (localTopic.isParent) {
      setTopicsLocal(prevTopicsLocal => {
        return prevTopicsLocal.filter((topic, i) => i !== index);
      });
    } else {
      setTopicsLocal(prevTopicsLocal => {

        return prevTopicsLocal.map((topic) => {
          if (topic.topic === localTopic.parent) {
            topic.subtopics = topic.subtopics.filter((subtopic, i) => i !== index);
          }
          return topic;
        })
      });
    }
  }

  const handleAddTopic = () => {
    setTopicsLocal(prevTopicsLocal => {
      return [{
        topic: !isCategoriesCustom ? 'Topic name' : 'Category name',
        description: !isCategoriesCustom ? 'Description of the topic' : 'Description of the category',
        isParent: true,
        parent: null,
        representativity: 0,
        subtopics: []
      }, ...prevTopicsLocal];
    });
  }

  const normalizeString = (str) => str.toLowerCase()
    .trim()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/\s*-\s*/g, "-");

  const handleSplit = async (parentTopic, maxChildren = 3, mode = 'auto') => {
    if (mode === 'manual') {
      setTopicsLocal(prevTopicsLocal => {
        return prevTopicsLocal.map((topic, i) => {
          let newTopic = {...topic}
          if (newTopic.topic === parentTopic) {
            newTopic.subtopics = [];
            for (let i = 0; i < maxChildren; i++) {
              newTopic.subtopics.push({
                topic: `Subtopic ${i + 1}`,
                description: `Description of subtopic ${i + 1}`,
                isParent: false,
                parent: parentTopic,
              })
            }
          }
          return newTopic;

        });
      });
    } else {
      let subtopics = await inferSubTopics(parentTopic, maxChildren);
      subtopics = subtopics.map(inferredSubtopic => {
        let newSubtopic = {...inferredSubtopic};
        topicsLocal.forEach(mainTopic => {
          if (mainTopic.subtopics?.length > 0) {
            mainTopic.subtopics.forEach(subtopic => {
              if (normalizeString(subtopic.topic) === normalizeString(newSubtopic.topic)) {
                newSubtopic.topic = `${inferredSubtopic.topic} (${parentTopic})`
              }
            })
          }
        })
        return newSubtopic;
      })
      setTopicsLocal(prevTopicsLocal => {
        return prevTopicsLocal.map((topic) => {
          let newTopic = {...topic}
          if (newTopic.topic === parentTopic) {
            newTopic.subtopics = subtopics;
          }
          return newTopic;
        });
      });
    }
  }

  React.useEffect(() => {
    setTopicsLocal(topics.map((topic) => {
      return {
        topic: topic.topic,
        description: topic.description,
        isParent: true,
        parent: null,
        icon: topic.icon,
        aiSummary: topic.aiSummary,
        image_url: topic.image_url,
        image_description: topic.image_description,
        subtopics: topic.subtopics ? topic.subtopics.map(subtopic => {
          let st = {...subtopic};
          st.isParent = false;
          st.parent = topic.topic;
          return st;
        }).sort((a, b) => b.representativity - a.representativity) : [],
        representativity: topic.representativity,
        count: topic.count
      }
    }));
  }, [topics])

  function findByTopic(list, topic) {
    return list.find(item => item.topic === topic);
  }

  const handleImport = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const importedData = JSON.parse(e.target.result);
        if (topicsLocal?.length === 0) {
          setTopicsLocal(importedData);
        } else {
          const localTopics = [...topicsLocal];
          importedData.forEach(secondItem => {
            let firstItem = findByTopic(localTopics, secondItem.topic);

            // If the topic does not exist in the first list, add it
            if (!firstItem) {
              localTopics.push(secondItem);
            } else {
              // If the topic exists but the subtopics are empty, add the subtopics
              if (firstItem.subtopics.length === 0 && secondItem.subtopics.length > 0) {
                firstItem.subtopics = secondItem.subtopics;
              }
            }
          });
          setTopicsLocal(localTopics);
        }
        // You can handle further processing here if needed
      } catch (error) {
        console.error('Error parsing JSON file', error);
      }
    };
    reader.readAsText(file);
  };

  const actions = (
    <Box sx={{width: '100%', display: 'flex', justifyContent: 'center'}}>
      <Grid container direction="row" sx={{width: '100%'}}>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <MuiButton onClick={() => exportTopics(topicsLocal,policyName)}
                     startIcon={<ExportIcon/>}>
            Export
          </MuiButton>
          <input type="file" onChange={handleImport} style={{display: 'none'}}
                 id="importFile" accept=".json"/>
          <label htmlFor="importFile">
            <MuiButton component="span" startIcon={<ImportIcon/>}>
              Import
            </MuiButton>
          </label>
        </Grid>
        <Grid item xs={6} display="flex" justifyContent="flex-end">
          <Box>
            <MuiButton onClick={handleClose} color="primary" variant="text">
              {t('cancel_btn')}
            </MuiButton>
            <MuiButton onClick={handleSubmit} startIcon={<SaveIcon/>}>
              {t('generic_save')}
            </MuiButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );


  return (
    <div>
      <Modal
        open={true}
        title={!isCategoriesCustom ? t('discussed_topics') : label}
        actions={actions}
        maxWidth={"lg"}
        onClose={handleClose}>
        <div style={{position: 'relative', minHeight: '200px', padding: '5px'}}>
          {/* minHeight and padding are optional, adjust based on your design */}

          {/* Position the Add icon button to the top right */}
          <div style={{position: 'absolute', top: 5, right: 8}}>
            <IconButton onClick={handleAddTopic}>
              <AddIcon/>
            </IconButton>
          </div>
          <div style={{marginTop: '40px'}}>
            {topicsLocal.map((topic, i) => renderCard(topic, i))}
          </div>
        </div>
      </Modal>
    </div>
  );
}
