import {Autocomplete, Paper, styled} from '@mui/material';

export const MuiItem = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary
}));

export const MuiAutocomplete = styled(Autocomplete)(({ theme }) => ({
  '& .MuiInputBase-root': {
    height: 'auto',
  },
}));

export const divCustomCategoriesStyle = {
  display: 'flex',
  flexWrap: 'wrap'
}

export const divTooltipCategoriesStyle = {
  marginRight: '10px',
  marginBottom: '10px'
}
