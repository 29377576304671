import React from "react"
import {Box, CardActions, CardContent, Grid, Typography} from '@mui/material'

// Steps
import FirstStep from "./Step/FirstStep";
import Policies from "./Step/Policies";
import FilterSetup from "./Step/FilterSetup";
import _ from 'lodash';
import {useDispatch, useSelector} from 'react-redux';
import {
  setTargetCollection,
  setTargetScheme,
  setTreemapTotalLoadingProgress
} from '../../../../store/appSlice';
import {useTranslation} from '../../../providers/TranslationProvider';
import {getRandomId} from '../../../../utils/formatter';
import {generateID, schemeChanged} from '../../../../utils/utils';
import useCreateScheme from '../../../../api/hooks/useCreateScheme';
import useUpdateScheme from '../../../../api/hooks/useUpdateScheme';
import {useLocation} from 'react-router-dom';
import {useSnackbar} from 'notistack';
import axios from '../../../../api/axios/axiosInstance';
import apiPaths from '../../../../api/apiPaths'
import {status200} from '../../../../api/status.utils';
import {MuiItem as Item} from './Step/form/styles/policy';
import {MuiButton, MuiCard} from './styles/stepForm';
import {useLoading} from "../../../providers/LoadingProvider";
import axiosEngineInstance from "../../../../api/axios/axiosEngineInstance";
import enginePaths from "../../../../api/enginePaths";

const initialValues = {
  dataset: '',
  name: '',
  description: ''
};
const defaultWeight = {
  propName: 'weight',
  label: 'weight',
  category: 'weight',
}
const StepForm = ({scenario, schemes, orgId, isWorkspace}) => {
  const {enqueueSnackbar} = useSnackbar()
  const {t} = useTranslation();
  const dispatch = useDispatch();
  const [scheme, setScheme] = React.useState(initialValues);
  const [selectedCollection, setSelectedCollection] = React.useState(null)
  const [weightFields, setWeightFields] = React.useState([defaultWeight])
  const [variables, setVariables] = React.useState([])
  const [policies, setPolicies] = React.useState([])
  const [schemeCollectionConfig, setSchemeCollectionConfig] = React.useState([])
  const [enableSave, setEnableSave] = React.useState(false)
  const [dataRegion, setDataRegion] = React.useState("US");
  const {
    setIsLoading,
    setIsClassificationLoading,
    setTitle,
    setShowProgressBar,
    setProgressMessage,
    setProgress
  } = useLoading();
  const targetScheme = useSelector(state => state.app.targetScheme)
  const targetCollection = useSelector(state => state.app.targetCollection)
  const schemeNameChange = false
  const {mutateAsync: createScheme} = useCreateScheme();
  const {mutateAsync: updateScheme} = useUpdateScheme();
  const location = useLocation();
  const isCreating = location.pathname.split('/')[4] === 'create'
  let schemeId = null

  const errorToast = (msg) => enqueueSnackbar(msg, {variant: 'error'})

  const successToast = (msg) => enqueueSnackbar(msg, {variant: 'success'})

  //methods
  const buildFilters = () => {
    let localCollection = _.cloneDeep(targetCollection)
    // console.log("Local collection", localCollection);
    console.log("Setting data region to: ", localCollection.region || "US")
    setDataRegion(localCollection.region || "US");
    setVariables(localCollection.variables);

    let localWeightFields = localCollection.variables
      .filter((d) => d.category === 'weight')
    setWeightFields(
      localWeightFields.length > 0 ? localWeightFields : [defaultWeight]
    )

    let schemeCollectionConfigLocal = scenario.scheme.schemeCollectionConfig ? _.cloneDeep(scenario.scheme.schemeCollectionConfig) : []

    setSchemeCollectionConfig(schemeCollectionConfigLocal)
  }

  const setLocalTargetScheme = () => {
    setSelectedCollection(scheme.dataset);
    setPolicies(_.cloneDeep(scheme.policies) || []);
    setSchemeCollectionConfig(scheme.schemeCollectionConfig)
  };

  const policySize = async (policy) => {
    const payload = {
      surveyId: selectedCollection,
      region: dataRegion||"US",
      questionId: policy.answerVar,
      audioVariable: policy.audioVar || null,
      weight: scheme.weight || defaultWeight,
      slice: policy.amountSamples ? policy.amountSamples : 5,
      segmentation: {},
      statistics: {},
      similarity_query: {},
      similarity: {},
      pick_closest: process.env.REACT_APP_PICK_CLOSEST_ANSWER === 'true',
      includeIcons: false,
      topics: policy.topics,
      customSegments: policy.customSegmentations,
      maxClassificationTopics: policy.maxClassificationTopics
    }
    return await axiosEngineInstance.post(enginePaths.aggregate_answers + '?head=True', payload, {
      ...status200
    }).catch((err) => {
      console.log("Error fetching data", err);
      return err;
    }).then((res) => {
      const data = res.data;
      return data["Content-Length"];
    });

  }

  const saveTargetScheme = async () => {
    const updatedPolicies = await Promise.all(policies.map(async (policy) => {
      const size = await policySize(policy);
      const policyCopy = {...policy, size};
      if (!policyCopy.classified) {
        policyCopy.classified = false
      }
      return policyCopy;
    }));
    let localTargetScheme = {
      id: scheme && scheme.id ? scheme.id : getRandomId(),
      name: scheme && scheme.name !== '' ? scheme.name : "scheme_" + getRandomId(),
      description: scheme.description,
      dataset: selectedCollection,
      weight: scheme.weight,
      segmentation_variables: scheme.segmentation_variables || [],
      statistic_variables: scheme.statistic_variables || [],
      policies: updatedPolicies,
      nameChange: schemeNameChange,
      schemeCollectionConfig: schemeCollectionConfig,
    };
    schemeId = localTargetScheme.id
    if (schemes.schemes.some((d) => d.id === localTargetScheme.id)) {

      let _schemeChanged = schemeChanged(localTargetScheme, scenario.scheme);
      if (_schemeChanged) {
        return updateScheme({
          user_id: orgId,
          scheme: localTargetScheme,
        });
      } else {
        return await new Promise((resolve) => {
          resolve(true);
        });
      }
    } else {

      return await createScheme({
        user_id: orgId,
        scheme: localTargetScheme,
      });
    }
  }


  const handleGoToChart = async () => {
    setIsLoading(true)
    let errors = []
    if (!validatePolicies(policies)) {
      errorToast('Fill all fields. You need to specify an answer column and at least 2 topics.')
      setIsLoading(false);
      return false
    }
    let classified_policies = await handleComputeClassification();

    for (const policy of classified_policies) {
      if (!policy.classified) {
        console.log(`Question ${policy.name} is not classified`)
        errors.push(`Query ${policy.name} is not classified`)
      }
    }
    if (errors.length > 0) {
      for (const error of errors) {
        errorToast(error)
      }
      setIsLoading(false);
      return false
    }

    try {
      const response = await saveTargetScheme();
      if (response) {
        successToast('Scheme Saved')
        const protocol = window.location.protocol
        const domain = window.location.host
        setIsLoading(false)
        window.location.href = `${protocol}//${domain}/orgs/${isWorkspace ? 'workspace' : orgId}/scenarios/${schemeId}/analysis`;
      }
    } catch (e) {
      errorToast('Scheme name is already be taken')

    }
    setIsLoading(false)
  }

  const processPolicy = async (policy) => {
    if (policy.classified) return policy;
    const inferenceMode = ["llm", "vector"].includes(policy.inferenceMode) ? policy.inferenceMode : "vector";
    const requestId = generateID();
    policy.requestId = requestId;

    const payload = {
      collection_name: scheme.dataset,
      answers_column: policy.answerVar,
      topics: policy.topics,
      model: inferenceMode,
      requestId: requestId,
      saveClassification: true,
      region: dataRegion||"US",
    };

    try {
      await axios.post(apiPaths.compute_async_classification, payload, status200);
    } catch (error) {
      console.error(error);
      policy.classified = false;
      errorToast(`Error computing classification for Question: ${policy.name}`);
    }
    return policy
  };

  const pollProgress = async (requestId, classified_policies, seenProgress, currentProgress, total_progress) => {
    let allCompleted = true;

    const progress_response = await axios.get(`${apiPaths.progress_status}?id=${requestId}`, status200);
    const response_body = progress_response.data;

    if (!response_body || !response_body.data) {
      return false;
    }

    const policy = classified_policies.find(p => p.requestId === requestId);
    const {message, status, progress} = response_body.data;

    if (status !== "success" && status !== "failed" && status !== 'error') {
      allCompleted = false;
    }

    if (status === 'failed' || status === 'error') {
      policy.classified = false;

    } else if (status === 'success') {
      policy.classified = true;
      const representativity = response_body.data.additionalData?.topic_representativity;
      if (representativity >= 0) {
        for (let topic of policy.topics) {
          topic.representativity = representativity[topic.topic];
          if (topic.subtopics && topic.subtopics.length > 0) {
            let overallRep = 0;
            for (let subtopic of topic.subtopics) {
              let stRepresentativity = representativity[subtopic.topic];
              subtopic.representativity = stRepresentativity;
              overallRep += stRepresentativity;
            }
            topic.representativity = overallRep;
          }

        }
      }
    }

    if (!seenProgress.includes(`${requestId}_${message}:${progress}`)) {
      seenProgress.push(`${requestId}_${message}:${progress}`);
      setProgressMessage(`${policy.name}: ${message}`);
      if (progress) {
        let norm_progress = Math.min(progress, 100)
        const updated_progress = ((currentProgress + norm_progress) / total_progress) * 100;
        setProgress(updated_progress);
      }
    }

    return allCompleted;
  };

  const pollUntilCompleted = (requestId, classified_policies, seenProgress, currentProgress, total_progress) => {
    return new Promise(async (resolve) => {
      if (await pollProgress(requestId, classified_policies, seenProgress, currentProgress, total_progress)) {
        resolve();
      } else {
        setTimeout(() => {
          pollUntilCompleted(requestId, classified_policies, seenProgress, currentProgress, total_progress).then(resolve);
        }, 1200);
      }
    });
  };

  const validatePolicies = (policies) => {
    const missingSomething = policies.some((x) => {
      return (
        x.answerVar === '' || x?.topics?.length < 2
      );
    });

    if (policies.length === 0 || missingSomething) {
      return false
    }
    return !missingSomething;
  }

  const handleComputeClassification = async () => {
    const classified_policies = [...policies];
    let total_progress = classified_policies.map(policy => policy.classified ? 0 : 1).reduce((acc, currentValue) => {
      return acc + currentValue;
    });
    let policyContribution = 0;
    total_progress *= 100;
    setShowProgressBar(true);
    setProgress(0);
    setIsClassificationLoading(true);
    setTitle("Categorizing your data with topics...");
    const seenProgress = [];
    for (let policy of classified_policies) {
      let processed_policy = await processPolicy(policy);
      if (processed_policy.requestId && !processed_policy.classified) {
        await pollUntilCompleted(processed_policy.requestId, classified_policies, seenProgress, policyContribution, total_progress);
        policyContribution += 100;
        if (policy.classified) {
          successToast("Question successfully classified");
        } else {
          errorToast(`Failed to classify '${policy.name}'`)
        }
      }
    }
    setShowProgressBar(false);
    setProgressMessage('');
    setTitle("");
    setIsClassificationLoading(false);
    handlePoliciesChange(classified_policies);
    return classified_policies;
  }


  const handleSaveScheme = async () => {
    setIsLoading(true)
    let errors = []
    // validate policies
    const valid_policies = validatePolicies(policies);
    if (!valid_policies) {
      setIsLoading(false);
      errorToast("Fill all fields. You need to specify an answer column and at least 2 topics.")
      return false
    }
    setIsLoading(false);
    setShowProgressBar(true);
    let classified_policies = await handleComputeClassification();

    setIsLoading(true);
    handlePoliciesChange(classified_policies);
    for (const policy of classified_policies) {
      if (!policy.classified) {
        console.log(`Question ${policy.name} is not classified`)
        errors.push(`Query ${policy.name} is not classified`)
      }
    }
    if (errors.length > 0) {
      for (const error of errors) {
        errorToast(error)
      }
      setIsLoading(false)
      return false;
    }


    try {
      const response = await saveTargetScheme();

      if (response) {
        try {
          setIsLoading(false)
          successToast('Scheme Saved')
        } catch (error) {
          console.log(error)
        }
        if (schemeId && location.pathname.split('/')[4] === 'create') {
          const protocol = window.location.protocol
          const domain = window.location.host
          window.location.href = `${protocol}//${domain}/orgs/${isWorkspace ? 'workspace' : orgId}/scenarios/${schemeId}/specification`;
        }
        return true;
      }
    } catch (e) {
      errorToast('Scheme name is already be taken')
      setIsLoading(false);
    }
    setIsLoading(false);
    return false;
  }


  const handleExportScheme = () => {
    let schemeDataToExport = {
      name: scheme && scheme.name !== '' ? scheme.name : "scheme_" + getRandomId(),
      policies: policies
    }

    const schemeDataToExportJson = JSON.stringify(schemeDataToExport);

    const anchorLinkDonwload = document.createElement('a');
    anchorLinkDonwload.href = 'data:text/json;charset=utf-8,' + encodeURIComponent(schemeDataToExportJson);
    anchorLinkDonwload.download = `${scheme.name}_export.json`;

    anchorLinkDonwload.click();
  }

  React.useEffect(() => {
    setScheme(scenario.scheme)
    dispatch(setTargetCollection(scenario.collection));
    dispatch(setTargetScheme(scenario.scheme));
  }, [scenario.scheme])

  React.useEffect(() => {
    if (targetCollection && Object.keys(targetCollection).length > 0) {
      buildFilters();
    }
  }, [scheme, targetCollection]);

  React.useEffect(() => {
    if (isCreating && targetCollection && Object.keys(targetCollection).length > 0) {
      buildFilters()
    }
  }, [isCreating, targetCollection]);

  React.useEffect(() => {
    if (targetScheme
      && Object.keys(targetScheme).length > 0
      && targetCollection
      && Object.keys(targetCollection).length > 0
    ) {
      setLocalTargetScheme();
      buildFilters();
    }
  }, [targetScheme, targetCollection, scheme]);

  React.useEffect(() => {
    if (scheme && selectedCollection && validatePolicies(policies)) {
      setEnableSave(true);
    } else {
      setEnableSave(false);
    }
  }, [scheme, targetCollection, policies]);


  const handlePoliciesChange = (newPolicies) => {
    setPolicies(newPolicies || []);
    if (scheme) {
      const localScheme = _.cloneDeep(scheme);
      localScheme.policies = newPolicies || [];
      setScheme(localScheme);
    }
  }


  return (
    <Grid container justifyContent="center" spacing={1}>
      <Grid item md={12}>
        <MuiCard>
          <CardContent style={{padding: 0}}>
            <Grid item container spacing={1} justifyContent="center">
              <Box sx={{flexGrow: 1}} style={{maxWidth: '100%'}}>
                {/*Step 1*/}
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <Typography>{t('select_dataset')}</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    <Item>
                      <FirstStep
                        collections={schemes.collections}
                        scheme={scheme}
                        weightFields={weightFields}
                        isCreating={isCreating}
                        setScheme={setScheme}
                        orgId={orgId}
                        handleExport={handleExportScheme}/>
                    </Item>
                  </Grid>
                </Grid>
                {(scheme && scheme.dataset !== '') && (
                  <Grid container spacing={2} sx={{marginTop: '20px'}}
                        direction='row'>
                    <Grid item xs={4}>
                      <Typography>{t('filters')}</Typography>
                    </Grid>
                    <Grid item xs={8}>
                      <FilterSetup scheme={scheme} setScheme={setScheme}
                                   categoricalFields={variables.filter((v) => v.category === 'categorical')}
                                   statisticFields={variables.filter((v) => v.category === 'statistic')}/>
                    </Grid>
                  </Grid>
                )}
                {scheme && scheme.dataset !== '' && (
                  <>
                    <div style={{
                      display: "flex",
                      marginTop: 30,
                      justifyContent: "flex-end"
                    }}/>

                    <div style={{
                      display: "flex",
                      marginTop: 30,
                      justifyContent: "flex-end"
                    }}/>

                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <Typography>{t('survey_questions')}</Typography>
                      </Grid>
                      <Grid item xs={8}>
                        <Policies
                          policies={policies}
                          dataRegion={dataRegion}
                          setPolicies={handlePoliciesChange}
                          variables={variables}
                          dataset={scheme.dataset}
                          weight={scheme.weight}
                          handleSaveScheme={saveTargetScheme}
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </Box>
            </Grid>
          </CardContent>
          <CardActions>
            <Grid container spacing={1}>
              <Grid item md={8}></Grid>
              <Grid item md={2}>
                <MuiButton
                  data-cy='btn-goto-chart'
                  variant="contained"
                  color="secondary"
                  type="Submit"
                  onClick={(e) => handleSaveScheme(e)}
                  disabled={!enableSave}>
                  {t('generic_save')}
                </MuiButton>
              </Grid>
              <Grid item md={2}>
                <MuiButton
                  data-cy='btn-goto-chart'
                  variant="contained"
                  color="secondary"
                  type="Submit"
                  disabled={!enableSave}
                  onClick={() => handleGoToChart()}>
                  {t('go_to_chart')}
                </MuiButton>
              </Grid>
            </Grid>
          </CardActions>
        </MuiCard>
      </Grid>
    </Grid>
  )
}

export default StepForm;
